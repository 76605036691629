* {
  margin: 0;
  padding: 0;
  font-family: "Outfit", sans-serif;
  color: #000;
}

.container {
  width: 90%;
  height: 100vh;
  margin: 0 auto;
}

@media (min-width: 800px) {
  .container {
    width: 65%;
  }
}
