* {
  text-decoration: none;
  text-transform: uppercase;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  font-size: 10px;
  margin: 30px 0;
}

h1 {
  font-size: 18px;
  font-weight: 400;
  color: #000;
  letter-spacing: 1px;
}

.navItem {
  font-weight: 600;
  letter-spacing: 2px;
  padding-bottom: 1px;
  font-size: 11px;
  margin-left: 20px;
}

.active {
  font-weight: 600;
  letter-spacing: 2px;
  border-bottom: 2px solid #000;
  padding-bottom: 1px;
  font-size: 11px;
  margin-left: 20px;
}

.line {
  width: 28px;
  height: 3px;
  background-color: #000;
  border-radius: 1px;
}

.line:nth-child(2) {
  margin: 3px 0;
}

.hamStyle {
  display: none;
}

@media (max-width: 800px) {
  .hamStyle {
    display: block;
  }

  .nav2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.9);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    margin: 0;
  }

  .nav {
    display: none;
  }

  .nav2 .navItem,
  .nav2 .active {
    margin: 20px 0;
    font-size: 16px;
    color: #fff;
    font-weight: 400;
  }
  .nav2 .active {
    border-bottom: 2px solid #fff;
  }
}
