.background {
  background-color: rgba(0, 0, 0, 0.85);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  color: var(--tertiary-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.triLeft {
  position: absolute;
  left: 0;
  top: 50%;
  width: 15px;
  height: 15px;
  transform: rotate(-90deg);
  padding: 5px;
}

.triRight {
  position: absolute;
  width: 15px;
  height: 15px;
  right: 0;
  top: 50%;
  transform: rotate(90deg);
  padding: 5px;
}

.background img {
  width: auto;
  max-width: 90%;
  max-height: 80vh;
}

.background p {
  color: var(--tertiary-color);
  text-transform: uppercase;
  margin-top: 15px;
  color: #fff;
  font-size: 14px;
  letter-spacing: 1px;
  text-align: center;
  max-width: 90%;
}

.background span {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  color: #fff;
  z-index: 999;
  cursor: pointer;
}
